<script setup lang="ts">
import { ref, watch } from 'vue'
import MultiSelect from 'primevue/multiselect'
import type { MultiSelectPassThroughMethodOptions } from 'primevue/multiselect'
import type { FormKitNode } from '@formkit/core'

export interface Props {
  options?: LabeledValue<string>[] | RecursiveLabeledValue<string>[]
  placeholder?: string
  disabled?: boolean
  required?: boolean
  context?: {
    // for use as formkit element
    options: LabeledValue<string>[] | RecursiveLabeledValue<string>[]
    placeholder?: string
    disabled?: boolean
    node: FormKitNode
  }
  default?: string[]
}
export interface Events {
  (e: 'hidden', value: boolean): void
}

const props = defineProps<Props>()
const emit = defineEmits<Events>()
const selection = ref(props.default ?? [])

if (props.context?.node.value) selection.value = props.context.node.value as string[]

// this maps selection to formkit input value
watch(selection, () => {
  if (!props.context?.node) return
  props.context.node.input(selection.value)
})

const pt = {
  root: {
    class: [
      'w-full inline-flex relative rounded-md bg-white dark:bg-black border border-gray-border dark:border-white-soft transition-all duration-200 cursor-pointer select-none',
      { 'opacity-60 pointer-events-none cursor-default': props.disabled },
    ],
  },
  labelContainer: {
    class: 'overflow-hidden flex flex-auto cursor-pointer',
  },
  label: {
    class:
      'leading-none block p-3 text-black dark:text-white/80 placeholder:text-black/400 dark:placeholder:text-white-soft transition duration-200 overflow-auto whitespace-nowrap cursor-pointer',
  },
  token: {
    class:
      'inline-flex items-center py-1.5 px-3 mr-2 rounded-[1.14rem] bg-primary/20 text-gray-dark dark:text-white-soft cursor-default',
  },
  removeTokenIcon: {
    class: 'ml-1 cursor-pointer',
  },
  trigger: {
    class:
      'flex items-center justify-center shrink-0 bg-transparent text-black dark:text-white-soft w-12 rounded-tr-md rounded-br-md',
  },
  panel: {
    class:
      'absolute top-0 left-0 border-0 dark:border rounded-md shadow-md bg-white dark:bg-black text-black dark:text-white dark:border-white-soft',
  },
  header: {
    class:
      'flex items-center justify-between py-3 px-5 m-0 border-b rounded-tl-md rounded-tr-md text-black dark:text-white bg-white-soft dark:bg-black-soft border-gray-border',
  },
  headerCheckboxContainer: {
    class: 'relative w-6 h-6 inline-flex align-bottom cursor-pointer select-none',
  },
  headerCheckbox: {
    root: {
      class: 'relative w-6 h-6 mr-2 inline-flex align-bottom cursor-pointer select-none',
    },
    box: ({ context }: MultiSelectPassThroughMethodOptions) => ({
      class: [
        'flex items-center justify-center w-6 h-6 rounded-md border-2',
        {
          'border-gray-border bg-white-soft dark:bg-black-soft': !context.selected,
          'border-gray-border bg-white dark:bg-black-soft': context.selected,
        },
        {
          'peer-hover:border-gray dark:peer-hover:border-white-soft': !props.disabled && !context.selected,
          'peer-hover:bg-white-soft dark:peer-hover:bg-gray peer-hover:border-blue-600 dark:peer-hover:border-white-soft':
            !props.disabled && context.selected,
          'cursor-default opacity-60': props.disabled,
        },
        'transition-colors duration-200',
      ],
    }),
    input: {
      class:
        'peer w-full  h-full absolute top-0 left-0 z-10 p-0 m-0 opacity-0 rounded-md outline-none border-2 border-gray-border appearance-none cursor-pointer',
    },
    icon: {
      class: 'w-4 h-4 text-base leading-none text-black dark:text-white transition-all duration-200',
    },
  },
  itemCheckbox: {
    root: {
      class: 'relative w-6 h-6 mr-2 inline-flex align-bottom cursor-pointer select-none',
    },
    box: ({ context }: MultiSelectPassThroughMethodOptions) => ({
      class: [
        'flex items-center justify-center w-6 h-6 rounded-md border-2',
        {
          'border-gray-border bg-white-soft dark:bg-black-soft': !context.selected,
          'border-gray-border bg-white dark:bg-black-soft': context.selected,
        },
        {
          'peer-hover:border-gray dark:peer-hover:border-white-soft': !props.disabled && !context.selected,
          'peer-hover:bg-white-soft dark:peer-hover:bg-gray peer-hover:border-blue-600 dark:peer-hover:border-white-soft':
            !props.disabled && context.selected,
          'cursor-default opacity-60': props.disabled,
        },
        'transition-colors duration-200',
      ],
    }),
    input: {
      class:
        'peer w-full  h-full absolute top-0 left-0 z-10 p-0 m-0 opacity-0 rounded-md outline-none border-2 border-gray-border appearance-none cursor-pointer',
    },
    icon: {
      class: 'text-base leading-none w-4 h-4 text-black dark:text-white transition-all duration-200',
    },
  },
  closeButton: {
    class:
      'relative flex items-center justify-center mr-2 last:mr-0 w-8 h-8 border-0 rounded-full text-black dark:text-white bg-transparent transition duration-200 ease-in-out overflow-hidden',
  },
  closeButtonIcon: {
    class: 'w-4 h-4 inline-block',
  },
  wrapper: {
    class: 'max-h-[400px] overflow-auto',
  },
  list: {
    class: 'py-3 list-none m-0',
  },
  item: ({ context }: MultiSelectPassThroughMethodOptions) => ({
    class: [
      'font-normal leading-none flex items-center relative border-0 rounded-none m-0 py-3 px-5',
      { 'text-black dark:text-white': !context.focused && !context.selected },
      { 'bg-white/60 dark:bg-black/60 text-black dark:text-white/80': context.focused && !context.selected },
      { 'bg-white-soft dark:bg-gray-mid text-black dark:text-white': context.focused && context.selected },
      { 'bg-white/200 dark:bg-black/40 text-gray dark:text-white/80': !context.focused && context.selected },
      'transition-shadow duration-200 cursor-pointer overflow-hidden whitespace-nowrap',
    ],
  }),
  itemgroup: {
    class: 'font-bold m-0 p-3 px-5 text-black dark:text-white bg-white dark:bg-black-soft cursor-auto',
  },
  filtercontainer: {
    class: 'relative w-full mx-2',
  },
  filterinput: {
    class:
      'font-sans leading-none pr-7 py-3 px-3 -mr-7 w-full text-black dark:text-white bg-white dark:bg-black-soft border-gray-border placeholder:text-gray dark:placeholder:text-gray-btn border rounded-lg appearance-none transition duration-200 focus:ring focus:outline-none focus:outline-offset-0 focus:ring-blue-400 dark:focus:ring-white-soft appearance-none',
  },
  filtericon: {
    class: 'absolute top-1/2 right-3 -mt-2',
  },
  clearicon: {
    class: 'text-black absolute top-1/2 right-12 -mt-2',
  },
  emptymessage: {
    class: 'leading-none py-3 px-5 text-black dark:text-white bg-transparent',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}
</script>

<template>
  <MultiSelect
    :pt
    v-model="selection"
    optionLabel="label"
    optionValue="value"
    :options="options ?? context?.options ?? []"
    :placeholder="placeholder ?? context?.placeholder ?? ''"
    :disabled="disabled ?? context?.disabled ?? false"
    @hide="emit('hidden', true)"
  >
    <template #option="{ option }">
      <slot name="option" v-bind="{ option }" />
    </template>
  </MultiSelect>
</template>
