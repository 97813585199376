import { ref, watch, inject } from 'vue'
import type { Locales, Namespaces } from '@i18n/i18n-types'
import { isNamespace } from '@i18n/i18n-util'
import { loadLocaleAsync, loadNamespaceAsync } from '@i18n/i18n-util.async'
import { typesafeI18n } from '@i18n/i18n-vue'
import { useRoute } from 'vue-router/auto'
import axios from 'axios'

export function spreadTheLocaleNews(newLocale: Locales, formKitConfig: Record<string, string>) {
  // set document language, eg <html lang="en">
  document.querySelector('html')?.setAttribute('lang', newLocale)
  // requests shall be sent with the new locale
  axios.defaults.headers.common['Accept-Language'] = newLocale
  axios.defaults.headers.common['content-language'] = newLocale
  // FormKit comes with its own localized strings
  formKitConfig.locale = newLocale
}

function loadAndSet(
  locale: Locales,
  namespaces: Namespaces[],
  setLocale: (l: Locales) => void,
  formKitConfig: Record<string, string>,
) {
  const loaders = [loadLocaleAsync(locale), ...namespaces.map((l) => loadNamespaceAsync(locale, l))]
  return Promise.all(loaders).then(() => {
    spreadTheLocaleNews(locale, formKitConfig)
    setLocale(locale)
  })
}

/**
 * Load locale namespaces given in route meta information and provide i18nObject.
 *
 * @param beforeSetLocale is called right before setLocale is called
 * @param afterSetLocale is called right after setLocale is called
 *
 * Route meta information now supports the locales property:
 *   locales: string[]        // additional namespaces to load, default []
 */
export default function useLocales({
  beforeSetLocale,
  afterSetLocale,
  namespaces = [],
}: {
  beforeSetLocale?: (newLocale: Locales) => void
  afterSetLocale?: (newLocale: Locales) => void
  namespaces?: Namespaces[]
} = {}) {
  const { locale, setLocale, LL } = typesafeI18n()
  const route = useRoute()
  // const namespaces: Namespaces[] = []

  namespaces.filter((l) => {
    const valid = isNamespace(l)
    if (!valid) console.error('asked for non-existent locale namespace', l)
    return valid
  })

  if (Array.isArray(route?.meta?.locales)) {
    route.meta.locales.forEach((l: any) => {
      if (isNamespace(l) && namespaces.indexOf(l) < 0) namespaces.push(l)
      else console.error('Route', route, 'asked for non-existent locale namespace', l)
    })
  }

  const ready = ref(false)
  const formKitConfig = inject(Symbol.for('FormKitConfig')) as Record<string, string>

  watch(
    locale,
    async (newLocale) => {
      ready.value = false
      if (beforeSetLocale) beforeSetLocale(newLocale)
      await loadAndSet(newLocale, namespaces, setLocale, formKitConfig)
      ready.value = true
      if (afterSetLocale) afterSetLocale(newLocale)
    },
    {
      immediate: true,
    },
  )

  return {
    LL,
    ready,
    locale,
    setLocale,
  }
}
